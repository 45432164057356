// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["waAsHqDZT"];

const serializationHash = "framer-8xCJV"

const variantClassNames = {waAsHqDZT: "framer-v-f9akeu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({activate, height, id, text, width, ...props}) => { return {...props, ma7owFkBO: text ?? props.ma7owFkBO ?? "Create set", sjXlVjOtK: activate ?? props.sjXlVjOtK} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;activate?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ma7owFkBO, sjXlVjOtK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "waAsHqDZT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap137m3m6 = activeVariantCallback(async (...args) => {
if (sjXlVjOtK) {
const res = await sjXlVjOtK(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-f9akeu", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"waAsHqDZT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 71, 81)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Zha3R1bSBNZWRpdW0=", "--framer-font-family": "\"Faktum Medium\", \"Faktum Medium Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-line-height": "110%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Create set</motion.p></React.Fragment>} className={"framer-1nj2w13"} data-framer-name={"Download"} data-highlight fonts={["CUSTOM;Faktum Medium"]} layoutDependency={layoutDependency} layoutId={"P5k8GEpne"} onTap={onTap137m3m6} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} text={ma7owFkBO} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8xCJV.framer-xcje0l, .framer-8xCJV .framer-xcje0l { display: block; }", ".framer-8xCJV.framer-f9akeu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 14px 24px 14px 24px; position: relative; width: min-content; }", ".framer-8xCJV .framer-1nj2w13 { cursor: pointer; flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8xCJV.framer-f9akeu { gap: 0px; } .framer-8xCJV.framer-f9akeu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-8xCJV.framer-f9akeu > :first-child { margin-left: 0px; } .framer-8xCJV.framer-f9akeu > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 146.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"ma7owFkBO":"text","sjXlVjOtK":"activate"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAVSUPZCqP: React.ComponentType<Props> = withCSS(Component, css, "framer-8xCJV") as typeof Component;
export default FramerAVSUPZCqP;

FramerAVSUPZCqP.displayName = "AltCv / ActionButton";

FramerAVSUPZCqP.defaultProps = {height: 50, width: 146.5};

addPropertyControls(FramerAVSUPZCqP, {ma7owFkBO: {defaultValue: "Create set", displayTextArea: false, title: "Text", type: ControlType.String}, sjXlVjOtK: {title: "Activate", type: ControlType.EventHandler}} as any)

addFonts(FramerAVSUPZCqP, [{explicitInter: true, fonts: [{family: "Faktum Medium", source: "custom", url: "https://framerusercontent.com/assets/noeCzztZDXkfv62oFHgMNTd44.woff2"}]}], {supportsExplicitInterCodegen: true})